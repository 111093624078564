<template>
    <div class="staff-total" v-if="status === 'success'">
        <div class="staff-total__box box">
            <table class="staff-total__table staff-total__table_striped">
                <colgroup>
                    <col class="staff-total__table-column staff-total__table-column_title">
                    <col class="staff-total__table-column staff-total__table-column_number">
                    <col class="staff-total__table-column staff-total__table-column_number-closed">
                    <col class="staff-total__table-column staff-total__table-column_number-opened">
                    <col class="staff-total__table-column staff-total__table-column_sum-fot">
                </colgroup>
                <thead>
                    <tr class="staff-total__table-row staff-total__table-row_head">
                        <th class="staff-total__table-cell staff-total__table-cell_head staff-total__table-cell_sortable"
                            scope="col"
                            @click="setSorting('title')"
                        >
                            <span class="staff-total__table-value">Источник</span>
                            <svg-icon class="staff-total__table-value staff-total__table-value_icon"
                                v-if="sorting.field === 'title' && sorting.direction === 'ASC'"
                                icon="chevron-up"
                            />
                            <svg-icon class="staff-total__table-value staff-total__table-value_icon"
                                v-if="sorting.field === 'title' && sorting.direction === 'DESC'"
                                icon="chevron-down"
                            />
                        </th>
                        <th class="staff-total__table-cell staff-total__table-cell_head staff-total__table-cell_sortable"
                            scope="col"
                            @click="setSorting('number')"
                        >
                            <span class="staff-total__table-value">Всего ставок</span>
                            <svg-icon class="staff-total__table-value staff-total__table-value_icon"
                                v-if="sorting.field === 'number' && sorting.direction === 'ASC'"
                                icon="chevron-up"
                            />
                            <svg-icon class="staff-total__table-value staff-total__table-value_icon"
                                v-if="sorting.field === 'number' && sorting.direction === 'DESC'"
                                icon="chevron-down"
                            />
                        </th>
                        <th class="staff-total__table-cell staff-total__table-cell_head staff-total__table-cell_sortable"
                            scope="col"
                            @click="setSorting('number_closed')"
                        >
                            <span class="staff-total__table-value">Занято</span>
                            <svg-icon class="staff-total__table-value staff-total__table-value_icon"
                                v-if="sorting.field === 'number_closed' && sorting.direction === 'ASC'"
                                icon="chevron-up"
                            />
                            <svg-icon class="staff-total__table-value staff-total__table-value_icon"
                                v-if="sorting.field === 'number_closed' && sorting.direction === 'DESC'"
                                icon="chevron-down"
                            />
                        </th>
                        <th class="staff-total__table-cell staff-total__table-cell_head staff-total__table-cell_sortable"
                            scope="col"
                            @click="setSorting('number_open')"
                        >
                            <span class="staff-total__table-value">Вакансий</span>
                            <svg-icon class="staff-total__table-value staff-total__table-value_icon"
                                v-if="sorting.field === 'number_open' && sorting.direction === 'ASC'"
                                icon="chevron-up"
                            />
                            <svg-icon class="staff-total__table-value staff-total__table-value_icon"
                                v-if="sorting.field === 'number_open' && sorting.direction === 'DESC'"
                                icon="chevron-down"
                            />
                        </th>
                        <th class="staff-total__table-cell staff-total__table-cell_head staff-total__table-cell_sortable"
                            scope="col"
                            @click="setSorting('sum_fot')"
                        >
                            <span class="staff-total__table-value">Суммарный ФОТ</span>
                            <svg-icon class="staff-total__table-value staff-total__table-value_icon"
                                v-if="sorting.field === 'sum_fot' && sorting.direction === 'ASC'"
                                icon="chevron-up"
                            />
                            <svg-icon class="staff-total__table-value staff-total__table-value_icon"
                                v-if="sorting.field === 'sum_fot' && sorting.direction === 'DESC'"
                                icon="chevron-down"
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="staff-total__table-row staff-total__table-row_body staff-total__table-row_pinned">
                        <td class="staff-total__table-cell staff-total__table-cell_body staff-total__table-cell_title">
                            Все подразделения
                        </td>
                        <td class="staff-total__table-cell staff-total__table-cell_body">
                            {{totalView.number | intFormat}}
                        </td>
                        <td class="staff-total__table-cell staff-total__table-cell_body">
                            {{totalView.number_closed | intFormat}}
                        </td>
                        <td class="staff-total__table-cell staff-total__table-cell_body">
                            {{totalView.number_open | intFormat}}
                        </td>
                        <td class="staff-total__table-cell staff-total__table-cell_body">
                            {{totalView.sum_fot | currencyFloatFormat}}
                        </td>
                    </tr>
                    <tr class="staff-total__table-row staff-total__table-row_body"
                        v-for="department in departmentsView" :key="department.id"
                    >
                        <td class="staff-total__table-cell staff-total__table-cell_body staff-total__table-cell_title">
                            <router-link class="link link_red"
                                :to="{ name: 'StaffDepartment', params: { id: department.id } }"
                                :title='`Смотреть штатную расстановку подразделения "${department.title}"`'>
                                {{department.title}}
                            </router-link>
                        </td>
                        <td class="staff-total__table-cell staff-total__table-cell_body">
                            {{calcPositionsTotal(departmentPositions(department)).number | intFormat}}
                        </td>
                        <td class="staff-total__table-cell staff-total__table-cell_body">
                            {{calcPositionsTotal(departmentPositions(department)).number_closed | intFormat}}
                        </td>
                        <td class="staff-total__table-cell staff-total__table-cell_body">
                            {{calcPositionsTotal(departmentPositions(department)).number_open | intFormat}}
                        </td>
                        <td class="staff-total__table-cell staff-total__table-cell_body">
                            {{calcPositionsTotal(departmentPositions(department)).sum_fot | currencyFloatFormat}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import { isTradePoint } from "@/helpers/departments";
    import { isAdministratorPosition } from "@/helpers/positions";

    export default {
        name: "StaffTotal",
        props: {
            status: {
                type: String,
                required: true
            },
            filters: {
                type: Array,
                required: true
            },
            filterId: {
                type: String,
                required: true
            },
            isFiltersVisible: {
                type: Boolean,
                required: true
            },
            isReportTotalLinkVisible: {
                type: Boolean,
                required: true
            },
            departments: {
                required: true
            },
            total: {
                required: true
            },
            userStaffUnits: {
                required: true
            },
            calcPositionsTotal: {
                type: Function,
                required: true
            }
        },
        computed: {
            ...mapState({
                sorting: state => state.staff.sortings.total
            }),
            departmentsView() {
                return this.departments?.map(department => {
                    department.ranking = Object.values(department.ranking);
                    return department;
                })?.filter(department => {
                    if (this.filterId === "all") {
                        return true;
                    }

                    return this.isTradePoint(department.id) && department.ranking.some(ranking => {
                        switch (this.filterId) {
                            case "administrator":
                                return this.isAdministratorPosition(ranking.position_short);
                            case "ordinary":
                                return !this.isAdministratorPosition(ranking.position_short);
                            default:
                                return true;
                        }
                    });
                })?.sort((department1, department2) => {
                    let first = this.sorting.field === "title" ? department1.title : department1.total[this.sorting.field];
                    let second = this.sorting.field === "title" ? department2.title : department2.total[this.sorting.field];

                    switch (this.sorting.field) {
                        case "title":
                            return compareStrings(first, second, this.sorting.direction);
                        default:
                            return compareNumbers(first, second, this.sorting.direction);
                    }
                });
            },
            positionsView() {
                return this.departmentsView?.reduce((positions, department) => {
                    return positions.concat(department.ranking);
                }, [])?.filter(positionn => {
                    switch (this.filterId) {
                        case "administrator":
                            return this.isAdministratorPosition(positionn.position_short);
                        case "ordinary":
                            return !this.isAdministratorPosition(positionn.position_short);
                        default:
                            return true;
                    }
                });
            },
            totalView() {
                return this.calcPositionsTotal(this.positionsView);
            }
        },
        methods: {
            ...mapMutations([
                "setStaffSortingDirection",
                "setStaffSortingField",
                "toggleStaffFilters",
                "toggleReportTotalLink"
            ]),
            setSorting(field) {
                if (this.sorting.field === field) {
                    this.setStaffSortingDirection({
                        table: "total",
                        direction: this.sorting.direction === "ASC" ? "DESC" : "ASC"
                    });
                } else {
                    this.setStaffSortingField({
                        table: "total",
                        field
                    });
                }
            },
            isTradePoint,
            isAdministratorPosition,
            departmentPositions(department) {
                return department.ranking?.filter(ranking => {
                    switch (this.filterId) {
                        case "administrator":
                            return this.isAdministratorPosition(ranking.position_short);
                        case "ordinary":
                            return !this.isAdministratorPosition(ranking.position_short);
                        default:
                            return true;
                    }
                });
            }
        },
        created() {
            if (this.userStaffUnits?.ids?.length === 1) {
                this.$router.push({
                    name: "StaffDepartment",
                    params:{
                        id: this.userStaffUnits?.ids[0]
                    }
                });
            } else {
                if (!this.isFiltersVisible) {
                    this.toggleStaffFilters(true);
                }

                if (this.isReportTotalLinkVisible) {
                    this.toggleReportTotalLink(false);
                }
            }
        }
    }
</script>

<style lang="scss">
    .staff-total__box {
        padding: 8px 16px 16px;
        overflow-x: auto;

        & + & {
            margin-top: 30px;
        }
    }
    .staff-total__table {
        width: 100%;
    }
    .staff-total__table-caption {
        padding: 8px 8px 0;
        font-size: 24px;
        font-weight: bold;
    }
    .staff-total__table-column {
        &_total {
            width: 25%;
        }
        &_title {
            width: 27.5%;
        }
        &_sum-fot {
            width: 27.5%;
        }
        &_number {
            width: 15%;
        }
        &_number-closed {
            width: 15%;
        }
        &_number-opened {
            width: 15%;
        }
    }
    .staff-total__table-row {
        &_body {
            .staff-total__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .staff-total__table-cell {
        font-size: 20px;
        padding: 16px 8px;

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }
    }
    .staff-total__table-value {
        vertical-align: middle;

        & + & {
            margin-left: 5px;
        }

        &_icon {
            width: 24px;
            height: 24px;
        }
    }
</style>
